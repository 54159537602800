import { NextPage } from 'next';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import { ReactElement, ReactNode, useEffect } from 'react';
import {
    ApolloProvider,
    initialAppState,
    useApollo,
    GlobalProvider,
    SubComponentUnion,
    setFeatureFlags,
    parseBoolean,
} from '@honeycomb/data';
import { getDataDogConfigSettings, loadAndInitDataDogRum, trackPageViewServer } from '@honeycomb/tracking';
import { GoogleTagManager, GoogleTagManagerNoScript, Layout, useMountEffect } from '@honeycomb/ui';
import {
    BreakpointProvider,
    DNATA_TRAVEL_THEME_OVERRIDES,
    DNATA_TRAVEL_CUSTOM_STYLE_PROPS,
    HoneycombThemeProvider,
    LazyImageProvider,
} from '@honeycomb/ui-core';
import { ErrorBoundary } from 'react-error-boundary';
import { v4 } from 'uuid';
import { searchCreatedRouting } from '@honeycomb/routing';
import { Footer } from 'components/Footer';
import { errorFallback } from '../components/error/errorFallback';
import { Masthead } from '../components/Masthead/Masthead';
import '../styles/OpenSans.css';

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

export type GenericSubComponentProps = {
    component: SubComponentUnion;
};

export type GetLayout<P> = (page: React.ReactElement, props: P) => ReactNode;

const { mockingEnabled } = getConfig()?.publicRuntimeConfig || { mockingEnabled: '' };
if (mockingEnabled === 'true') {
    import('@honeycomb/data').then((mod) => mod.mockData());
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
    // Individual pages can specify a custom layout by implementing a getLayout property
    // See https://nextjs.org/docs/basic-features/layouts for more detail.

    const getLayout: GetLayout<typeof pageProps> =
        Component.getLayout ??
        ((page, props) => (
            <Layout masthead={<Masthead />} footer={<Footer />} {...props}>
                {page}
            </Layout>
        ));

    useMountEffect(() => {
        const { env, service, version } = pageProps?.context?.config ?? {};
        const datadogConfig = getDataDogConfigSettings(pageProps?.configSettings);
        if (parseBoolean(datadogConfig?.HcDataDogRealUserMonitoringEnabled)) {
            loadAndInitDataDogRum({
                env,
                service,
                version,
                applicationId: datadogConfig.HcDataDogApplicationId,
                clientToken: datadogConfig.HcDataDogClientToken,
                sessionReplaySampleRate: parseInt(datadogConfig.HcDataDogSessionReplaySampleRate),
                sessionSampleRate: parseInt(datadogConfig.HcDataDogSessionSampleRate),
                trackLongTasks: parseBoolean(datadogConfig.HcDataDogTrackLongTasks),
                trackResources: parseBoolean(datadogConfig.HcDataDogTrackResources),
                trackUserInteractions: parseBoolean(datadogConfig.HcDataDogTrackUserInteractions),
            });
        }
    });
    // Must be called before any other initializeApollo call (inc. that via initialAppState) to ensure userContext is added to apolloClient singleton
    // TODO: Refactor initialAppState to resolve this flakiness
    const apolloClient = useApollo(pageProps);

    const query = pageProps.context?.query || null;

    if (query) {
        setFeatureFlags(pageProps.configSettings, query);
    }

    // Checks the query params and sets up the reactive vars, must happen client-side
    useEffect(() => {
        initialAppState(query, searchCreatedRouting);
    }, [query]);

    const isMobile = pageProps?.deviceDetection?.isMobile;
    const isTablet = pageProps?.deviceDetection?.isTablet;
    const ssrPredictedWidth = isTablet ? 768 : isMobile ? 375 : 1024;

    if (typeof window === 'undefined') {
        trackPageViewServer(pageProps);
    }

    return (
        <GlobalProvider
            resourceStrings={pageProps.resourceStrings}
            configSettings={pageProps.configSettings}
            globalContents={pageProps.globalContents}
            currentContext={pageProps.currentContext}
            deviceInfo={pageProps.deviceDetection}
        >
            <HoneycombThemeProvider
                theme={DNATA_TRAVEL_THEME_OVERRIDES}
                customStyleProps={DNATA_TRAVEL_CUSTOM_STYLE_PROPS}
                cultureCode={pageProps.currentContext?.cultureCode}
            >
                <BreakpointProvider ssrPredictedWidth={ssrPredictedWidth}>
                    <ApolloProvider client={apolloClient}>
                        <Head>
                            {/* https://nextjs.org/docs/messages/no-document-viewport-meta */}
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        </Head>
                        <LazyImageProvider>
                            <GoogleTagManagerNoScript />
                            {getLayout(
                                <ErrorBoundary
                                    resetKeys={[pageProps.context?.req.url]}
                                    fallbackRender={errorFallback(v4())}
                                >
                                    <GoogleTagManager />
                                    <Component {...pageProps} />
                                </ErrorBoundary>,
                                pageProps
                            )}
                        </LazyImageProvider>
                    </ApolloProvider>
                </BreakpointProvider>
            </HoneycombThemeProvider>
        </GlobalProvider>
    );
}
