export var ConfigSettingsKey;
(function (ConfigSettingsKey) {
    ConfigSettingsKey["CovidCoverCheckboxEnabled"] = "CovidCoverCheckboxEnabled";
    ConfigSettingsKey["CrossSellInsuranceEnabled"] = "CrossSellInsuranceEnabled";
    ConfigSettingsKey["DisableCustomerReviews"] = "DisableCustomerReviews";
    ConfigSettingsKey["EmailMyQuoteCheckBoxEnabled"] = "EmailMyQuoteCheckBoxEnabled";
    ConfigSettingsKey["EnableCityTax"] = "EnableCityTax";
    ConfigSettingsKey["FacebookAppId"] = "FacebookAppId";
    ConfigSettingsKey["FlightSelectionExtended"] = "FlightSelectionExtended";
    ConfigSettingsKey["GoogleAppId"] = "GoogleAppId";
    ConfigSettingsKey["GoogleAuthProvider"] = "GoogleAuthProvider";
    ConfigSettingsKey["HCGoogleTagManagerId"] = "HCGoogleTagManagerId";
    ConfigSettingsKey["HcCovidProtection"] = "HcCovidProtection";
    ConfigSettingsKey["HCDisableRobots"] = "HCDisableRobots";
    ConfigSettingsKey["HCEnablePayMonthly"] = "HCEnablePayMonthly";
    ConfigSettingsKey["HcIncludeInsuranceDisclaimer"] = "HcIncludeInsuranceDisclaimer";
    ConfigSettingsKey["HcPaymentApplicationType"] = "HcPaymentApplicationType";
    ConfigSettingsKey["HcSearchUnitOptions"] = "HcSearchUnitOptions";
    ConfigSettingsKey["HcExperimentalFeatures"] = "HcExperimentalFeatures";
    ConfigSettingsKey["HideSomeUsp"] = "HideSomeUsp";
    ConfigSettingsKey["HotelImageCDN"] = "HotelImageCDN";
    ConfigSettingsKey["HotelPlaceholderImage"] = "HotelPlaceholderImage";
    ConfigSettingsKey["IncludeCityTaxInHolidaysPrice"] = "IncludeCityTaxInHolidaysPrice";
    ConfigSettingsKey["IncludeCityTaxInHotelOnlyPrice"] = "IncludeCityTaxInHotelOnlyPrice";
    ConfigSettingsKey["InsuranceEnabled"] = "InsuranceEnabled";
    ConfigSettingsKey["LowDepositEnabled"] = "LowDepositEnabled";
    ConfigSettingsKey["PayBalanceEnabled"] = "PayBalanceEnabled";
    ConfigSettingsKey["PCIFrameOrigin"] = "PCIFrameOrigin";
    ConfigSettingsKey["PCIFrameUrl"] = "PCIFrameUrl";
    ConfigSettingsKey["ShowAtolLogo"] = "ShowAtolLogo";
    ConfigSettingsKey["ShowDisclaimer"] = "ShowDisclaimer";
    ConfigSettingsKey["ShowItaaLogo"] = "ShowItaaLogo";
    ConfigSettingsKey["ThreeDS2ChallengeUrl"] = "ThreeDS2ChallengeUrl";
    ConfigSettingsKey["ThreeDS2DdcGatewayUrl"] = "ThreeDS2DdcGatewayUrl";
    ConfigSettingsKey["ThreeDS2DdcTimeout"] = "ThreeDS2DdcTimeout";
    ConfigSettingsKey["ThreeDS2EnabledHC"] = "ThreeDS2EnabledHC";
    ConfigSettingsKey["ThreeDS2PaymentGatewayDomain"] = "ThreeDS2PaymentGatewayDomain";
    ConfigSettingsKey["WebtrendsAccountId"] = "WebtrendsAccountId";
    ConfigSettingsKey["WebtrendsEnabled"] = "WebtrendsEnabled";
    ConfigSettingsKey["WebTrendsExcludedPages"] = "WebTrendsExcludedPages";
    ConfigSettingsKey["HcBypassTermsAndConditionsCheckBox"] = "HcBypassTermsAndConditionsCheckBox";
    ConfigSettingsKey["HcHotelHolidayFilter"] = "HcHotelHolidayFilter";
    ConfigSettingsKey["HcFlightFilter"] = "HcFlightFilter";
    ConfigSettingsKey["HcHotelSortOptions"] = "HcHotelSortOptions";
    ConfigSettingsKey["Platform195EnabledPages"] = "Platform195EnabledPages";
    ConfigSettingsKey["Platform195AdvertPlacement"] = "Platform195AdvertPlacement";
    ConfigSettingsKey["Platform195AccountId"] = "Platform195AccountId";
    ConfigSettingsKey["ShowSpecificTransferRequirements"] = "ShowSpecificTransferRequirements";
    ConfigSettingsKey["HcSignInLink"] = "HcSignInLink";
    ConfigSettingsKey["HCEnableMYBNotifications"] = "HCEnableMYBNotifications";
    ConfigSettingsKey["HCSupportedMYBNotificationTypes"] = "HCSupportedMYBNotificationTypes";
    ConfigSettingsKey["HcPayBalanceMinimumAmount"] = "HcPayBalanceMinimumAmount";
    ConfigSettingsKey["MybThreeDs2ModalFullScreen"] = "MybThreeDs2ModalFullScreen";
    ConfigSettingsKey["HCEnableTripadvisorReviews"] = "HCEnableTripadvisorReviews";
    ConfigSettingsKey["HCTripadvisorPartnerId"] = "HCTripadvisorPartnerId";
    ConfigSettingsKey["RaiseCSRAfterReturnDays"] = "RaiseCSRAfterReturnDays";
    ConfigSettingsKey["HcDecimalSeperator"] = "HcDecimalSeperator";
    ConfigSettingsKey["EnableVoucherCode"] = "EnableVoucherCode";
    ConfigSettingsKey["HcMarketOverlay"] = "HcMarketOverlay";
    ConfigSettingsKey["isEnableVoucherCodeForTransfer"] = "isEnableVoucherCodeForTransfer";
    ConfigSettingsKey["HCPaymentPlansOrder"] = "HCPaymentPlansOrder";
    ConfigSettingsKey["HcPayPalPayTodayClientId"] = "HcPayPalPayTodayClientId";
    ConfigSettingsKey["HcPayPalPayLaterClientId"] = "HcPayPalPayLaterClientId";
    ConfigSettingsKey["PaypalEnablePayin3Messaging"] = "PaypalEnablePayin3Messaging";
    ConfigSettingsKey["HcDisplaySearchUnit"] = "HcDisplaySearchUnit";
    ConfigSettingsKey["ApplePayMerchantIdentifier"] = "ApplePayMerchantIdentifier";
    ConfigSettingsKey["ApplePayMerchantName"] = "ApplePayMerchantName";
    ConfigSettingsKey["HcApplePayMerchantCapabilities"] = "HcApplePayMerchantCapabilities";
    ConfigSettingsKey["HcApplePaySupportedNetworks"] = "HcApplePaySupportedNetworks";
    ConfigSettingsKey["HcApplePayRequiredBillingContactFields"] = "HcApplePayRequiredBillingContactFields";
    ConfigSettingsKey["HcApplePayBaseConfiguration"] = "HcApplePayBaseConfiguration";
    ConfigSettingsKey["HCEnableHotelOnlyTransfers"] = "HCEnableHotelOnlyTransfers";
    ConfigSettingsKey["MybSupportedPaymentTypes"] = "MybSupportedPaymentTypes";
    ConfigSettingsKey["CustomerRatingFilterEnabled"] = "CustomerRatingFilterEnabled";
    ConfigSettingsKey["GooglePayEnable"] = "GooglePayEnable";
    ConfigSettingsKey["GooglePayMerchantId"] = "GooglePayMerchantId";
    ConfigSettingsKey["GooglePayMerchantName"] = "GooglePayMerchantName";
    ConfigSettingsKey["GooglePayEnvironment"] = "GooglePayEnvironment";
    ConfigSettingsKey["GooglePayDisabledBrowsers"] = "GooglePayDisabledBrowsers";
    ConfigSettingsKey["HcPaymentStructureExceptions"] = "HcPaymentStructureExceptions";
    ConfigSettingsKey["HcSupportedPaymentTypes"] = "HcSupportedPaymentTypes";
    ConfigSettingsKey["BundlePaymentEnabled"] = "BundlePaymentEnabled";
    ConfigSettingsKey["EmailMyHotelQuoteEnabled"] = "EmailMyHotelQuoteEnabled";
    ConfigSettingsKey["EmailMyHolidayQuoteEnabled"] = "EmailMyHolidayQuoteEnabled";
    ConfigSettingsKey["HcPaymentMethodExceptions"] = "HcPaymentMethodExceptions";
    ConfigSettingsKey["HCDestinationSearchToggle"] = "HCDestinationSearchToggle";
    ConfigSettingsKey["HCBudgetFilterRange"] = "HCBudgetFilterRange";
    ConfigSettingsKey["HCPayDepositEnabled"] = "HCPayDepositEnabled";
    ConfigSettingsKey["HCEnablePricingCalendar"] = "HCEnablePricingCalendar";
    ConfigSettingsKey["HCPricingCalendarMaxNumberofNights"] = "HCPricingCalendarMaxNumberofNights";
    ConfigSettingsKey["HCTimeZone"] = "HCTimeZone";
    ConfigSettingsKey["HCHideSponsoredHotelMessage"] = "HCHideSponsoredHotelMessage";
    ConfigSettingsKey["HCHideInsuranceInformation"] = "HCHideInsuranceInformation";
    ConfigSettingsKey["HCHideNoInsuranceModalSubheader"] = "HCHideNoInsuranceModalSubheader";
    ConfigSettingsKey["HCManageExtras"] = "HCManageExtras";
    ConfigSettingsKey["GooglePayGatewayMerchantId"] = "GooglePayGatewayMerchantId";
    ConfigSettingsKey["GooglePayGatewayMerchantName"] = "GooglePayGatewayMerchantName";
    ConfigSettingsKey["GoogleMapsAPIKey"] = "GoogleMapsAPIKey";
    ConfigSettingsKey["HcPayPalPayLaterMinMax"] = "HcPayPalPayLaterMinMax";
    ConfigSettingsKey["HcPayPalPayLaterNumInstallments"] = "HcPayPalPayLaterNumInstallments";
    ConfigSettingsKey["HCResponseTapClassName"] = "HCResponseTapClassName";
    ConfigSettingsKey["HCResponseTapInstallationId"] = "HCResponseTapInstallationId";
    ConfigSettingsKey["HcBudgetFilterRangeEnabled"] = "HcBudgetFilterRangeEnabled";
    ConfigSettingsKey["HcCustomCsrArivetimeInterval"] = "HcCustomCsrArivetimeInterval";
    ConfigSettingsKey["HCPopUpExcludedUrls"] = "HCPopUpExcludedUrls";
    ConfigSettingsKey["HCPopUpCategoryUrls"] = "HCPopUpCategoryUrls";
    ConfigSettingsKey["HCPopUpTriggerInactivityDuration"] = "HCPopUpTriggerInactivityDuration";
    ConfigSettingsKey["HCPopUpTriggerScrolledPercentage"] = "HCPopUpTriggerScrolledPercentage";
    ConfigSettingsKey["HCPopUpTriggerIntentToLeave"] = "HCPopUpTriggerIntentToLeave";
    ConfigSettingsKey["HCPopUpCookieExpirationDuration"] = "HCPopUpCookieExpirationDuration";
    ConfigSettingsKey["HCPopUpSubscribeCookieExpirationDuration"] = "HCPopUpSubscribeCookieExpirationDuration";
    ConfigSettingsKey["HcPayPalVaultingEnabled"] = "HcPayPalVaultingEnabled";
    ConfigSettingsKey["HCPromoBarCategoryUrls"] = "HCPromoBarCategoryUrls";
    ConfigSettingsKey["HCPromoBarExcludedUrls"] = "HCPromoBarExcludedUrls";
    ConfigSettingsKey["SoftEmailSignUpMode"] = "SoftEmailSignUpMode";
    ConfigSettingsKey["AwaitingImagePlaceholder"] = "AwaitingImagePlaceholder";
    ConfigSettingsKey["HCHotelonlyTransferChannels"] = "HCHotelonlyTransferChannels";
    ConfigSettingsKey["HcDataDogRealUserMonitoringEnabled"] = "HcDataDogRealUserMonitoringEnabled";
    ConfigSettingsKey["HcDataDogApplicationId"] = "HcDataDogApplicationId";
    ConfigSettingsKey["HcDataDogClientToken"] = "HcDataDogClientToken";
    ConfigSettingsKey["HcDataDogServiceName"] = "HcDataDogServiceName";
    ConfigSettingsKey["HcDataDogSessionReplaySampleRate"] = "HcDataDogSessionReplaySampleRate";
    ConfigSettingsKey["HcDataDogSessionSampleRate"] = "HcDataDogSessionSampleRate";
    ConfigSettingsKey["HcDataDogTrackLongTasks"] = "HcDataDogTrackLongTasks";
    ConfigSettingsKey["HcDataDogTrackResources"] = "HcDataDogTrackResources";
    ConfigSettingsKey["HcDataDogTrackUserInteractions"] = "HcDataDogTrackUserInteractions";
    ConfigSettingsKey["HcBillingAddressLookupEnabled"] = "HcBillingAddressLookupEnabled";
    ConfigSettingsKey["HcGoogleAddressEnabled"] = "HcGoogleAddressEnabled";
    ConfigSettingsKey["RestrictByCookiePaymentMethodArray"] = "RestrictByCookiePaymentMethodArray";
    ConfigSettingsKey["HCEnableRecentView"] = "HCEnableRecentView";
    ConfigSettingsKey["HCMybFacebookLoginEnabled"] = "HCMybFacebookLoginEnabled";
    ConfigSettingsKey["MYBNOTRIPSPLACEHOLDERBGIMGMOBILE"] = "MYBNOTRIPSPLACEHOLDERBGIMGMOBILE";
    ConfigSettingsKey["MYBNOTRIPSPLACEHOLDERBGIMGDESKTOP"] = "MYBNOTRIPSPLACEHOLDERBGIMGDESKTOP";
    ConfigSettingsKey["HCSearchDuration"] = "HCSearchDuration";
    ConfigSettingsKey["HCEnableEstabLandingPriceCalendar"] = "HCEnableEstabLandingPriceCalendar";
    ConfigSettingsKey["HCSource"] = "HCSource";
    ConfigSettingsKey["HCSourceBasedPaymentPlansOrder"] = "HCSourceBasedPaymentPlansOrder";
    ConfigSettingsKey["HCNewHotelExtraPage"] = "HCNewHotelExtraPage";
    ConfigSettingsKey["MYBAmendmentsEnabled"] = "MYBAmendmentsEnabled";
    ConfigSettingsKey["MYBAmendmentsDatesEnabled"] = "MYBAmendmentsDatesEnabled";
    ConfigSettingsKey["MYBAmendmentsCancellationsEnabled"] = "MYBAmendmentsCancellationsEnabled";
    ConfigSettingsKey["HCDefaultCalendarConfig"] = "HCDefaultCalendarConfig";
    ConfigSettingsKey["HCDestinationCalendarConfig"] = "HCDestinationCalendarConfig";
    ConfigSettingsKey["HotelHolidayMaxRooms"] = "HotelHolidayMaxRooms";
    ConfigSettingsKey["HotelHolidayMaxAdultsPerRoom"] = "HotelHolidayMaxAdultsPerRoom";
    ConfigSettingsKey["HotelHolidayMaxChildrenPerRoom"] = "HotelHolidayMaxChildrenPerRoom";
    ConfigSettingsKey["HotelHolidayMaxChildAge"] = "HotelHolidayMaxChildAge";
    ConfigSettingsKey["HotelHolidayDefaultAdultsPerRoom"] = "HotelHolidayDefaultAdultsPerRoom";
    ConfigSettingsKey["ShowInternalCustomerScore"] = "ShowInternalCustomerScore";
    ConfigSettingsKey["ProductCacheEnabled"] = "ProductCacheEnabled";
    ConfigSettingsKey["HcMaxPassengerAdultFlights"] = "HcMaxPassengerAdultFlights";
    ConfigSettingsKey["HcMaxPassengerChildrenFlights"] = "HcMaxPassengerChildrenFlights";
    ConfigSettingsKey["HcMaxChildAgeFlights"] = "HcMaxChildAgeFlights";
    ConfigSettingsKey["HcMinAdultPassengersFlights"] = "HcMinAdultPassengersFlights";
    ConfigSettingsKey["TransferAutoCompleterMaxResults"] = "TransferAutoCompleterMaxResults";
    ConfigSettingsKey["HcFlightsSortOptions"] = "HcFlightsSortOptions";
    ConfigSettingsKey["EnbdPaymentOptionId"] = "EnbdPaymentOptionId";
    ConfigSettingsKey["showCovidWaiverDialog"] = "showCovidWaiverDialog";
    ConfigSettingsKey["HcSearchFunnelOptions"] = "HcSearchFunnelOptions";
    ConfigSettingsKey["CPGPaymentProviderEnabled"] = "CPGPaymentProviderEnabled";
    ConfigSettingsKey["HcShowExtrasInsurancePopup"] = "HcShowExtrasInsurancePopup";
    ConfigSettingsKey["HCEnableBookingCompleteInfo"] = "HCEnableBookingCompleteInfo";
    ConfigSettingsKey["HCEnableDestinationGroups"] = "HCEnableDestinationGroups";
    ConfigSettingsKey["HcBookingOptionalPostCode"] = "HcBookingOptionalPostCode";
    ConfigSettingsKey["HCRecordPriceDifferenceEnabled"] = "HCRecordPriceDifferenceEnabled";
    ConfigSettingsKey["HcHotelPageSizeDesktop"] = "HcHotelPageSizeDesktop";
    ConfigSettingsKey["HcHotelPageSizeMobile"] = "HcHotelPageSizeMobile";
    ConfigSettingsKey["DefaultSearchDepartureAirport"] = "DefaultSearchDepartureAirport";
    ConfigSettingsKey["DefaultSearchDuration"] = "DefaultSearchDuration";
    ConfigSettingsKey["DefaultSearchDestination"] = "DefaultSearchDestination";
    ConfigSettingsKey["EnableBetaEstabPage"] = "EnableBetaEstabPage";
    ConfigSettingsKey["EnableShortlist"] = "EnableShortlist";
    ConfigSettingsKey["HCEmptyWishlistCards"] = "HCEmptyWishlistCards";
    ConfigSettingsKey["HCShowInsurancePolicyWordingLink"] = "HCShowInsurancePolicyWordingLink";
    ConfigSettingsKey["EnableResendOtpENBD"] = "EnableResendOtpENBD";
    ConfigSettingsKey["HCPaymentConfirmHideCostBreakdown"] = "HCPaymentConfirmHideCostBreakdown";
    ConfigSettingsKey["TwoStepPassengersFormEnabled"] = "TwoStepPassengersFormEnabled";
    ConfigSettingsKey["HcMarketOverlayFlightsFunnel"] = "HcMarketOverlayFlightsFunnel";
    ConfigSettingsKey["CpgPaymentPollingEnabled"] = "CpgPaymentPollingEnabled";
    ConfigSettingsKey["CpgPaymentPollingDelayTimer"] = "CpgPaymentPollingDelayTimer";
    ConfigSettingsKey["CpgPaymentPollingMaxRetry"] = "CpgPaymentPollingMaxRetry";
    ConfigSettingsKey["HcAccountMenuEnabled"] = "HcAccountMenuEnabled";
    ConfigSettingsKey["EnableWishlistLandingPageRedirection"] = "EnableWishlistLandingPageRedirection";
    ConfigSettingsKey["EnableMultipleAvailabilityCalls"] = "EnableMultipleAvailabilityCalls";
    ConfigSettingsKey["EnableFareRules"] = "EnableFareRules";
    ConfigSettingsKey["EnableCreateUserWithEmailOnly"] = "EnableCreateUserWithEmailOnly";
    ConfigSettingsKey["PassengerFormRegistrationEnabled"] = "PassengerFormRegistrationEnabled";
    ConfigSettingsKey["HCSearchMonthRolloverMaxDaysLeft"] = "HCSearchMonthRolloverMaxDaysLeft";
    ConfigSettingsKey["EnableMockErrors"] = "EnableMockErrors";
    ConfigSettingsKey["EnableEstabOffers"] = "EnableEstabOffers";
    ConfigSettingsKey["HcRoomConfirmAverageTimeHoliday"] = "HcRoomConfirmAverageTimeHoliday";
    ConfigSettingsKey["HcRoomConfirmAverageTimeHotel"] = "HcRoomConfirmAverageTimeHotel";
    ConfigSettingsKey["HCMYBTransfersLinkEnabled"] = "HCMYBTransfersLinkEnabled";
})(ConfigSettingsKey || (ConfigSettingsKey = {}));
export const configSettingsRegistry = new Set(Object.values(ConfigSettingsKey));
