var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Container, Button, Stacker, TypographyBody, TypographyHeading, Styler } from '@honeycomb/ui-core';
export function UnexpectedError({ correlationId, children, error, resetErrorBoundary }) {
    const routeHome = () => __awaiter(this, void 0, void 0, function* () {
        if (resetErrorBoundary) {
            resetErrorBoundary();
        }
        window.location.href = '/';
    });
    React.useEffect(() => {
        if (!error)
            return;
        fetch('/log/error', {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                'x-trp-correlation-id': correlationId || '',
            },
            body: JSON.stringify({ message: error.message, stack: error.stack, source: 'Crane' }),
        });
    });
    return (React.createElement(Container, { mt: 10 },
        React.createElement(Stacker, { spacing: 6, alignItems: "center" },
            React.createElement("div", null,
                React.createElement(TypographyHeading, { size: "l", component: "h1", align: "center", mb: 4 }, "Something went wrong..."),
                React.createElement(TypographyBody, { align: "center" }, "Sorry about that, we are working on fixing up the place. Please go back to the homepage and try again.")),
            React.createElement(Button, { onClick: routeHome, color: "callToAction", size: "l" }, "Take me home"),
            React.createElement(Stacker, { spacing: 3 },
                correlationId && React.createElement(TypographyBody, { align: "center" }, correlationId),
                error && ['development', 'test'].includes(process.env.NODE_ENV) ? (React.createElement(TypographyBody, { align: "center", color: "system.error.base" }, error.message)) : null)),
        children && React.createElement(Styler, { mt: 6 }, children)));
}
