import { isEqual } from 'lodash';
import { clearLastViewedEstab } from '../hotels';
export const clearLastSearch = () => {
    return typeof window !== 'undefined' && window.localStorage.removeItem('lastSearch');
};
export function setLastSearch(searchState) {
    if (searchState && typeof window !== 'undefined') {
        // Set the lastSearch in local storage to be the searchState specified, if one entry exists that will be
        // updated with new searchState.
        clearLastSearch();
        clearLastViewedEstab();
        window.localStorage.setItem('lastSearch', JSON.stringify(searchState));
    }
}
export const getLastSearch = () => {
    return typeof window !== 'undefined' && window.localStorage.getItem('lastSearch');
};
export function updateLastSearch(searchState) {
    const searchData = getLastSearch();
    if (!searchData) {
        setLastSearch(searchState);
        return;
    }
    // Parse previous search state, assuming it should be JSON
    let prevSearchState;
    try {
        prevSearchState = JSON.parse(searchData);
    }
    catch (_a) {
        // If JSON parsing fails, treat it as a change and update
        setLastSearch(searchState);
        return;
    }
    if (!isEqual(searchState, prevSearchState))
        setLastSearch(searchState);
}
