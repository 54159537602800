import { clearLastSearch, getLastViewedEstab, getLastSearch, clearLastViewedEstab, TripType, DateSearchType, } from '@honeycomb/data';
import { getDetailsPageUrl } from '@honeycomb/routing';
import { getDateSummary } from './getDateSummary';
import { getSelectedOptionSummary } from './getSelectedOptionSummary';
import { addDay, addDays, getDayDiff, toUTCDate } from './date-utils';
// Function to extract SingleAirport entries
const extractSingleAirports = (airports) => {
    const singleAirports = [];
    airports.forEach((airportGroup) => {
        if (airportGroup.__typename === 'SingleAirport') {
            singleAirports.push({
                code: airportGroup.code,
                title: airportGroup.title,
                country: airportGroup.country,
            });
        }
        else if (airportGroup.__typename === 'AirportGroup' && airportGroup.subAirports) {
            airportGroup.subAirports.forEach((subAirport) => {
                if (subAirport) {
                    singleAirports.push({
                        code: subAirport.code,
                        title: subAirport.title,
                        country: subAirport.country,
                    });
                }
            });
        }
    });
    if (singleAirports.length) {
        const uniqueSet = new Set(singleAirports.map((airport) => JSON.stringify(airport)));
        return Array.from(uniqueSet).map((entry) => JSON.parse(entry));
    }
    return singleAirports;
};
function getFlightDetails(param, airports) {
    var _a;
    if (param.tripType === TripType.Holiday && ((_a = param.departureAirports) === null || _a === void 0 ? void 0 : _a.length) && airports) {
        const airportList = extractSingleAirports(airports);
        const selectedAirport = airportList.filter((airport) => { var _a; return (_a = param.departureAirports) === null || _a === void 0 ? void 0 : _a.includes(airport.code); });
        return getSelectedOptionSummary(selectedAirport);
    }
    return '';
}
function updateSearchState(searchParam, estabId, estabName) {
    var _a, _b;
    if (!searchParam)
        return searchParam;
    let state = Object.assign({}, searchParam);
    if (searchParam.destinations && searchParam.destinations.length > 0) {
        const hotelDestination = {
            id: estabId,
            type: 1,
            title: estabName,
            subTitle: '',
            isCached: (_b = (_a = searchParam.destinations[0]) === null || _a === void 0 ? void 0 : _a.isCached) !== null && _b !== void 0 ? _b : false,
        };
        state = Object.assign(Object.assign({}, state), { destinations: [hotelDestination] });
    }
    else if (searchParam.destinationIds && searchParam.destinationIds.length > 0) {
        state = Object.assign(Object.assign({}, state), { hotelIds: [estabId], hotelTitle: estabName, destinationIds: null });
    }
    return state;
}
export function mapDetails(estab, searchParam, airports) {
    var _a, _b, _c;
    const param = searchParam;
    param.tripStateId = estab.tripStateId;
    const detailsPageUrl = getDetailsPageUrl(param, estab.estabId);
    const isMonthSearch = param.dateSearchType === DateSearchType.ChooseMonth;
    const data = {
        hotelName: estab.name,
        rating: estab.starRating,
        travelDate: getDateSummary(new Date(isMonthSearch ? (_a = param.wholeMonthStartDate) !== null && _a !== void 0 ? _a : '' : param.startDate), new Date(isMonthSearch ? (_b = param.wholeMonthEndDate) !== null && _b !== void 0 ? _b : '' : param.endDate), true),
        hotelImageUrl: `${estab.imageUrl}?width=89&height=85&crop=true&quality=80`,
        detailsPageHref: detailsPageUrl,
        flightDetails: getFlightDetails(param, airports),
        tripType: (_c = param.tripType) !== null && _c !== void 0 ? _c : TripType.Hotel,
        destinationDetails: param.tripType === TripType.Hotel ? estab.location : '',
    };
    return data;
}
export function useStoredRecentViewed(airportData) {
    var _a;
    let data = null;
    const estabData = getLastViewedEstab();
    const searchData = getLastSearch();
    const clearRecentViewStorage = () => {
        clearLastSearch();
        clearLastViewedEstab();
        data = null;
    };
    if (estabData && !searchData)
        clearLastViewedEstab();
    if (estabData && searchData) {
        const estabDetails = JSON.parse(estabData);
        const searchDetails = JSON.parse(searchData);
        if (getDayDiff(new Date().toISOString(), searchDetails.startDate) > 0 ||
            getDayDiff(new Date().toISOString(), (_a = searchDetails.wholeMonthEndDate) !== null && _a !== void 0 ? _a : '') > 0) {
            const updatedDetails = updateSearchState(searchDetails, estabDetails.estabId, estabDetails.name);
            data = mapDetails(estabDetails, updatedDetails, airportData === null || airportData === void 0 ? void 0 : airportData.airports);
        }
        else {
            clearRecentViewStorage();
        }
    }
    return [data, clearRecentViewStorage];
}
function getNextMonthDates() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const nextMonthFirstDate = currentMonth === 11 ? new Date(currentYear + 1, 0, 1) : new Date(currentYear, currentMonth + 1, 1);
    const nextMonthLastDate = currentMonth === 11 ? new Date(currentYear + 1, 1, 0) : new Date(currentYear, currentMonth + 2, 0);
    return {
        startDate: nextMonthFirstDate.toISOString().split('T')[0],
        endDate: nextMonthLastDate.toISOString().split('T')[0],
    };
}
function updateDates(searchDetails, baseState, isCached) {
    var _a;
    const currentDateString = new Date().toISOString();
    const isChooseMonth = searchDetails.dateSearchType === DateSearchType.ChooseMonth;
    const isStartDateInPast = getDayDiff(currentDateString, searchDetails.startDate) < 1;
    let updatedDates = {
        startDate: searchDetails.startDate,
        endDate: searchDetails.endDate,
        wholeMonthStartDate: searchDetails.wholeMonthStartDate,
        wholeMonthEndDate: searchDetails.wholeMonthEndDate,
        dateSearchType: searchDetails.dateSearchType,
    };
    if (isChooseMonth) {
        if (!isCached) {
            updatedDates = {
                startDate: baseState.startDate,
                endDate: baseState.endDate,
                wholeMonthStartDate: '',
                wholeMonthEndDate: '',
                dateSearchType: DateSearchType.ChooseDate,
            };
        }
        else {
            updatedDates.startDate = '';
            updatedDates.endDate = '';
            if (getDayDiff(currentDateString, (_a = searchDetails.wholeMonthEndDate) !== null && _a !== void 0 ? _a : '') < 1) {
                const nextMonthDates = getNextMonthDates();
                updatedDates.wholeMonthStartDate = nextMonthDates.startDate;
                updatedDates.wholeMonthEndDate = nextMonthDates.endDate;
            }
        }
    }
    else if (isStartDateInPast) {
        updatedDates = {
            startDate: baseState.startDate,
            endDate: baseState.endDate,
            wholeMonthStartDate: '',
            wholeMonthEndDate: '',
            dateSearchType: DateSearchType.ChooseDate,
        };
    }
    return updatedDates;
}
const getDestinationDetails = (id, title, legacyType, isCacheEnabled = false, isCached = false) => {
    if (!id) {
        return { destinations: null, destinationIds: null, destinationTitle: '', hotelIds: null, hotelTitle: '' };
    }
    const destId = parseInt(id);
    if (isCacheEnabled) {
        return {
            destinations: [{ id: destId, type: legacyType, title: title !== null && title !== void 0 ? title : '', isCached }],
            destinationIds: null,
            destinationTitle: '',
            hotelIds: null,
            hotelTitle: '',
        };
    }
    return {
        destinations: null,
        destinationIds: legacyType === 1 ? [] : [destId],
        destinationTitle: legacyType === 1 ? '' : title !== null && title !== void 0 ? title : '',
        hotelIds: legacyType !== 1 ? [] : [destId],
        hotelTitle: legacyType !== 1 ? '' : title !== null && title !== void 0 ? title : '',
    };
};
const getDefaultSearch = (search) => {
    var _a;
    try {
        if (!search)
            return null;
        return (_a = JSON.parse(search)) !== null && _a !== void 0 ? _a : null;
    }
    catch (error) {
        console.error('Error on parsing default search:', error);
        return null;
    }
};
const getBaseState = (prepopulatedInput) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { searchParam, id, title, legacyType, destination, duration, airports, adults, isCacheEnabled, isCached } = prepopulatedInput;
    const searchDestinations = getDefaultSearch(destination);
    const destId = id || ((_b = (_a = searchDestinations === null || searchDestinations === void 0 ? void 0 : searchDestinations.id) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '');
    const destTitle = title || ((_c = searchDestinations === null || searchDestinations === void 0 ? void 0 : searchDestinations.title) !== null && _c !== void 0 ? _c : '');
    const destType = (_d = (legacyType || (searchDestinations === null || searchDestinations === void 0 ? void 0 : searchDestinations.type))) !== null && _d !== void 0 ? _d : 0;
    const isCachedValue = id ? isCached : searchDestinations === null || searchDestinations === void 0 ? void 0 : searchDestinations.isCached;
    const travelduration = (_e = parseInt(duration)) !== null && _e !== void 0 ? _e : 7;
    const adultCount = (_f = parseInt(adults)) !== null && _f !== void 0 ? _f : 2;
    const { destinations, destinationIds, destinationTitle, hotelIds, hotelTitle } = getDestinationDetails(destId, destTitle, destType, isCacheEnabled, isCachedValue);
    const currentDate = new Date();
    const baseState = Object.assign(Object.assign({}, searchParam), { hotelIds,
        hotelTitle,
        destinations,
        destinationIds,
        destinationTitle, startDate: toUTCDate(addDay(currentDate, travelduration)), endDate: toUTCDate(addDay(currentDate, travelduration * 2)), departureAirports: (_g = airports.split('|')) !== null && _g !== void 0 ? _g : null, rooms: [
            {
                adults: adultCount,
                childAges: [],
            },
        ], adults: adultCount });
    return baseState;
};
export function updateLandingPageSearch(prepopulatedInput) {
    try {
        const baseState = getBaseState(prepopulatedInput);
        const searchData = getLastSearch();
        if (!searchData)
            return baseState;
        const { isCached } = prepopulatedInput;
        const searchDetails = JSON.parse(searchData);
        const { startDate, endDate, wholeMonthStartDate, wholeMonthEndDate, dateSearchType } = updateDates(searchDetails, baseState, isCached);
        return Object.assign(Object.assign({}, baseState), { destinations: searchDetails.destinations, hotelIds: searchDetails.hotelIds, hotelTitle: searchDetails.hotelTitle, destinationIds: searchDetails.destinationIds, destinationTitle: searchDetails.destinationTitle, startDate,
            endDate,
            wholeMonthEndDate,
            wholeMonthStartDate, duration: dateSearchType === DateSearchType.ChooseMonth ? searchDetails.duration : null, dateSearchType, adults: searchDetails.adults, childAges: searchDetails.childAges, rooms: searchDetails.rooms, departureAirports: searchDetails.departureAirports && searchDetails.departureAirports.length > 0
                ? searchDetails.departureAirports
                : baseState.departureAirports });
    }
    catch (error) {
        console.error('Error updating landingPage search:', error);
        return prepopulatedInput.searchParam;
    }
}
export function getEstabOfferRequest(request) {
    var _a, _b, _c, _d, _e;
    const baseState = getBaseState(request);
    let inputrequest = {
        searchParameter: baseState,
        estabOffersRequest: {
            adults: (_a = baseState.adults) !== null && _a !== void 0 ? _a : 2,
            airportCodes: (_b = baseState.departureAirports) !== null && _b !== void 0 ? _b : [],
            children: 0,
            duration: parseInt(request.duration),
            estabID: request.id,
            checkInStartDate: baseState.startDate,
            checkInEndDate: baseState.startDate,
        },
    };
    try {
        const searchData = getLastSearch();
        if (!searchData)
            return inputrequest;
        const searchDetails = JSON.parse(searchData);
        const { startDate, endDate, wholeMonthStartDate, wholeMonthEndDate, dateSearchType } = updateDates(searchDetails, baseState, request.isCached);
        const airportCodes = searchDetails.departureAirports && searchDetails.departureAirports.length > 1
            ? searchDetails.departureAirports
            : baseState.departureAirports;
        let checkInStartDate = startDate;
        let checkInEndDate = startDate;
        const currentDate = new Date();
        const utcDate = (_c = currentDate.toISOString().split('T')[0]) !== null && _c !== void 0 ? _c : '';
        // in case of whole month and also if start date is less than current date
        if (wholeMonthStartDate) {
            checkInStartDate = wholeMonthStartDate;
            if (getDayDiff(utcDate, wholeMonthStartDate) < 0)
                checkInStartDate = utcDate;
            checkInEndDate = wholeMonthEndDate !== null && wholeMonthEndDate !== void 0 ? wholeMonthEndDate : '';
        }
        // in case flexible days as +/- 2/3 days
        else if (searchDetails.flexibleDays && searchDetails.flexibleDays > 0) {
            checkInStartDate = addDays(startDate, -searchDetails.flexibleDays);
            if (getDayDiff(utcDate, checkInStartDate) < 0) {
                checkInStartDate = startDate;
            }
            else {
                checkInEndDate = addDays(startDate, searchDetails.flexibleDays);
            }
        }
        inputrequest = {
            searchParameter: Object.assign(Object.assign({}, baseState), { startDate,
                endDate, flexibleDays: searchDetails.flexibleDays, wholeMonthEndDate,
                wholeMonthStartDate, duration: dateSearchType === DateSearchType.ChooseMonth ? searchDetails.duration : null, dateSearchType, adults: searchDetails.adults, childAges: searchDetails.childAges, rooms: searchDetails.rooms, departureAirports: airportCodes }),
            estabOffersRequest: {
                adults: (_d = baseState.adults) !== null && _d !== void 0 ? _d : 2,
                airportCodes: airportCodes !== null && airportCodes !== void 0 ? airportCodes : [],
                children: 0,
                duration: (_e = (dateSearchType === DateSearchType.ChooseMonth
                    ? searchDetails.duration
                    : getDayDiff(startDate, endDate))) !== null && _e !== void 0 ? _e : parseInt(request.duration),
                estabID: request.id,
                checkInStartDate,
                checkInEndDate,
            },
        };
        return inputrequest;
    }
    catch (error) {
        console.error('Error creating estabOffer input request:', error);
        return inputrequest;
    }
}
