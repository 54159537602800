import { FlightSortCriterion, SortCriterion } from '../contracts/types';
import { currentTripStateIdVar } from '../client/search/currentTripState';
import { currentRoutingTabVar } from '../client/routing-tabs/currentRoutingTab';
import { currentHotelFilterSelectionsVar } from '../client/filter/currentHotelFilters';
import { currentDeepLinkPriceTypeVar, currentFlightFilterSelectionsVar, currentFlightSortCriterionVar, currentHotelSortCriterionVar, currentTransferAirportVar, } from '../client';
import { setSearchState } from '../client/search/setSearchState';
import { FilterProductEnum, isCriteriaDifferent, PriceType, QueryParamsEnum, validateSearchQueryParams } from '../util';
import { extractFilters } from '../util/filters/extractFilters';
// Set up the initial app state from the query
export function initialAppState(query, newSearchSideEffect) {
    var _a, _b, _c, _d, _e;
    if (!query)
        return;
    const queryTripId = (_a = query[QueryParamsEnum.TRIP_STATE_ID]) === null || _a === void 0 ? void 0 : _a.toString();
    const currentTripId = currentTripStateIdVar();
    const isDeepLink = !queryTripId && validateSearchQueryParams(query);
    const isNewTripId = queryTripId && currentTripId !== queryTripId;
    if (isDeepLink || isNewTripId) {
        setSearchState(queryTripId, query, newSearchSideEffect);
    }
    const queryHotelFilterCriteria = extractFilters(FilterProductEnum.HOTEL, query);
    const currentFilterCriteria = currentHotelFilterSelectionsVar();
    if (isCriteriaDifferent(queryHotelFilterCriteria, currentFilterCriteria)) {
        currentHotelFilterSelectionsVar(queryHotelFilterCriteria);
    }
    const queryFlightFilterCriteria = extractFilters(FilterProductEnum.FLIGHT, query);
    const currentFlightFilterCriteria = currentFlightFilterSelectionsVar();
    if (isCriteriaDifferent(queryFlightFilterCriteria, currentFlightFilterCriteria)) {
        currentFlightFilterSelectionsVar(queryFlightFilterCriteria);
    }
    const querySortCriterion = (_b = query[QueryParamsEnum.SORT]) === null || _b === void 0 ? void 0 : _b.toString();
    const currentSortCriterion = currentHotelSortCriterionVar();
    if (!querySortCriterion) {
        currentHotelSortCriterionVar(SortCriterion.RecommendedHighestFirst);
    }
    if (querySortCriterion &&
        querySortCriterion !== currentSortCriterion &&
        Object.values(SortCriterion).includes(querySortCriterion)) {
        currentHotelSortCriterionVar(querySortCriterion);
    }
    const queryFlightSortCriterion = (_c = query[QueryParamsEnum.FSORT]) === null || _c === void 0 ? void 0 : _c.toString();
    const currentFlightSortCriterion = currentFlightSortCriterionVar();
    if (!queryFlightSortCriterion) {
        currentFlightSortCriterionVar(FlightSortCriterion.RecommendedHighestFirst);
    }
    if (queryFlightSortCriterion &&
        queryFlightSortCriterion !== currentFlightSortCriterion &&
        Object.values(FlightSortCriterion).includes(queryFlightSortCriterion)) {
        currentFlightSortCriterionVar(queryFlightSortCriterion);
    }
    if (query.idString && query.slug) {
        const routeSlug = query.slug.length && query.slug[1] ? query.slug[1] : '';
        currentRoutingTabVar(routeSlug);
    }
    const queryDeepLinkPriceType = (_d = query[QueryParamsEnum.DEEP_LINK_PRICE_TYPE]) === null || _d === void 0 ? void 0 : _d.toString();
    const currentDeepLinkPriceType = currentDeepLinkPriceTypeVar();
    if (queryDeepLinkPriceType &&
        queryDeepLinkPriceType !== currentDeepLinkPriceType &&
        Object.values(PriceType).includes(queryDeepLinkPriceType)) {
        currentDeepLinkPriceTypeVar(queryDeepLinkPriceType);
    }
    const airport = (_e = query[QueryParamsEnum.AIRPORT]) === null || _e === void 0 ? void 0 : _e.toString();
    const currentTransferAirport = currentTransferAirportVar();
    if (airport && airport !== currentTransferAirport) {
        currentTransferAirportVar(airport);
    }
}
